module.exports = {
  Settings: {
    appName: "starlight venues",
    appVersion: "1.0",
    mainDomain: "starlightvenues.com",
    groupDomain: "starlightvenues.com",
    siteId: "648b2fca24aa9a00078bda47",
    mainUrl: "https://demo-staging.companycheckout.com/#/",
    gqlServer: {
      url: "https://api.starlightvenues.com/",
      ws: "wss://api.starlightvenues.com/",
      // url: 'http://localhost:4003'
    },
    tourUsers: ["6488161d24aa9a00078bd9f6"],
    productId: '648b2fca24aa9a00078bda46',
    eventId: '648a24b324aa9a00078bda35',
  },
};
