<template>
  <div>
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
const default_layout = 'default';

export default {
  name: "app",
  computed: {
    layout() {
      console.log(this.$route.meta.layout)
      return (this.$route.meta.layout || default_layout) + '-layout';
    }
  },
}
</script>